/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("/assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("/assets/fonts/Inter-Regular.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("/assets/fonts/Inter-Bold.woff2") format("woff2"),
    url("/assets/fonts/Inter-Bold.woff") format("woff");
}

@layer base {
    a {
        @apply underline hover:no-underline hover:text-blue-700 dark:hover:text-blue-400 font-bold;
    }

    .card {
        @apply p-7 mt-10 ring-2 dark:ring-blue-700/40;
        border-radius: 1.7rem;

        @media (min-width: 1024px) {
            @apply mt-16 p-10;
            border-radius: 2.5rem;
        }

        &-primary {
            @apply bg-primary ring-primary/20 text-white;
        }

        &-secondary {
            @apply bg-secondary ring-secondary/20 text-white;
        }

        &-light {
            @apply bg-light ring-secondary/10;

            .card-subheading {
                @apply text-blue-600 dark:text-blue-300;
            }
        }

        &-heading {
            @apply text-2xl font-bold mt-4;
        }

        &-subheading {
            @apply text-sm text-blue-200 font-bold uppercase tracking-wider;
        }

        &-body {
            @apply mt-4 leading-relaxed;
        }

        &-footer {
            @apply rounded-b-none;
        }
    }
}

#hand-emoji {
    display: inline-block;
    animation-name: wave;
    animation-duration: 2.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 2.5s;
    transform-origin: 76% 67.5%;
}

/* The animation code */
@keyframes wave {
    5%, 15%, 25%, 35% {
        transform: rotateZ(20deg);
    }

    10%, 20%, 30%, 40% {
        transform: rotateZ(-30deg);
    }

    45% {
        transform: rotateZ(0deg);
    }
}